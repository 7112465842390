// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-internal-article-js": () => import("/opt/build/repo/src/templates/NewsInternalArticle.js" /* webpackChunkName: "component---src-templates-news-internal-article-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("/opt/build/repo/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-image-gallery-js": () => import("/opt/build/repo/src/pages/image-gallery.js" /* webpackChunkName: "component---src-pages-image-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("/opt/build/repo/src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-organic-cannabis-js": () => import("/opt/build/repo/src/pages/organic-cannabis.js" /* webpackChunkName: "component---src-pages-organic-cannabis-js" */),
  "component---src-pages-plans-js": () => import("/opt/build/repo/src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-sites-js": () => import("/opt/build/repo/src/pages/sites.js" /* webpackChunkName: "component---src-pages-sites-js" */),
  "component---src-pages-video-gallery-js": () => import("/opt/build/repo/src/pages/video-gallery.js" /* webpackChunkName: "component---src-pages-video-gallery-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

